import MasonryItem from "./MasonryItem";

function Masonry() {
    return (
        <div className="masonry-wrapper">
            <div className="masonry">
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
                <MasonryItem />
            </div>
        </div>
    )
}

export default Masonry;