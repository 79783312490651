import React from "react";
import './App.css';
import Masonry from "./Masonry/Masonry";
import logo from './w_large_logo.svg';
import condensedLogo from './w_condensed_logo.svg';
import sarah3 from './sarah-003.jpg';

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: null,
            error: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                {
                    name: event.target.name.value,
                    email: event.target.email.value,
                    category: event.target.category.value,
                    message: event.target.message.value
                }
            )
        };

        const response = await fetch('https://sp-emailer.bridgepour.repl.co/post/', requestOptions);
        const data = await response.json();
        this.setState({success: data.success});
    }

    render() {
        return (
            <div className="App">
                <div className="centered-box">
                    <div className="row">
                        <div className="col-12 text-center logo-container">
                            <picture>
                                <source media="(min-width:768px)" srcSet={logo} />
                                <img src={condensedLogo} className="logo" alt="Strawberry Photography Logo" />
                            </picture>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-12 pink-bg p-4">
                            <h1>Hello, welcome!</h1>
                            <p>My website is having a little make over - So i have popped this in place so you still
                                have a
                                way to chat with me.</p>
                            <p>I have just launched my Family Photo Sessions at <a href="https://spadework.org.uk/"
                                                                                   target="_blank"
                                                                                   rel="noopener noreferrer">Spadework</a> which
                                i am so so excited for - So
                                if you are wanting some info about that then drop your info over on the next section and
                                i
                                shall get back to you pronto.</p>
                            <p>Weddings (My favourite!!), maternity shoots, events and all other things photography - I
                                also
                                have you covered! Just pop your details down and I shall be in touch soon.</p>
                            <p>Check out my work on Instagram and Facebook and get
                                some inspiration and lets talk!</p>
                            <p>Much love</p>
                            <p>Sarah</p>
                            <br/>
                            <div className="d-grid gap-2">
                                <a href="https://www.facebook.com/strawberryphotography26" target="_blank" rel="noopener noreferrer"
                                   className="btn btn-light brand-facebook"><i
                                    className="bi bi-facebook"/> Facebook
                                </a>
                                <a href="https://www.instagram.com/strawberry_photography26/" target="_blank" rel="noopener noreferrer"
                                   className="btn btn-light brand-instagram"><i
                                    className="bi bi-instagram"/> Instagram
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 white-bg p-4">
                            <h1>Get in touch</h1>
                            <p>If you want to know more about me, or what I can offer for your special day, all you have
                                to
                                do is say hello! - don't forget to add in a bit about what you are after too, amazing! I
                                look forward to speaking with you.</p>
                            {this.state.success == null && (
                                <form onSubmit={this.handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Your Name</label>
                                        <input name="name" type="text" className="form-control" id="name"
                                               placeholder="Sarah" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Your Email address (So I can
                                            reply
                                            to you)</label>
                                        <input name="email" type="email" className="form-control" id="email"
                                               placeholder="sarah@strawberryphotography.co.uk" required />
                                    </div>
                                    <div className="mb-3">
                                        <select className="form-select" aria-label="Default select example" name="category" id="category" defaultValue="0">
                                            <option>Select a photoshoot to discuss</option>
                                            <option value="Family">Family</option>
                                            <option value="Spadework Family">Spadework Family</option>
                                            <option value="Wedding">Wedding</option>
                                            <option value="Maternity">Maternity</option>
                                            <option value="Events">Events</option>
                                            <option value="Other / Not listed">Other / Not listed</option>
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-label">Your message</label>
                                        <textarea name="message" className="form-control" id="message" rows="6" required />
                                    </div>

                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-dark">Submit</button>
                                    </div>
                                </form>
                            )}


                            {![null, true].includes(this.state.success) && (
                                <div className="ui inverted segment" id="form-fallback">
                                    <h3>Uh-oh! That's embarrassing.</h3>
                                    <p>Something went wrong with the form, don't worry though, send us an email the
                                        old fashioned way instead.</p>
                                    <p>Click the button below to take you to your email client of choice prefilled
                                        with the information you entered just now.</p>

                                    <div className="d-grid gap-2">
                                        <a href={`mailto:sarah@strawberryphotography.co.uk?subject=Website%20Enquiry&body=From:${this.state.name} \r\n ${this.state.message}`}
                                           className="btn btn-light" id="email-instead">Email me!</a>
                                    </div>
                                </div>
                            )}

                            <div className="alert alert-success" role="alert" id="thanks" style={{display: "None"}}>
                                <h3>Thanks for getting in touch!</h3>
                                <p>I've received your message and will be in touch as soon as I can, thank you :)</p>
                            </div>

                            {this.state.success && (
                                <div className="alert alert-success" role="alert">
                                    <h3>Thanks for getting in touch, {this.state.name}!</h3>
                                    <p>I've received your message and will be in touch as soon as I can, thank you :)</p>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-4 col-12 pink-bg p-4">
                            <h1>Who am I</h1>
                            <p>A wife, mother of 2, foodie, lover of bright clothes and a pretty damn good photographer
                                if I do say so myself.</p>
                            <p>What makes me happy? Having built up the best clientele who keep on coming back, I love
                                surprising people
                                who are adamant it's impossible to get a good photo fo their family.... It's the best
                                feeling giving
                                families photos to treasure forever.</p>
                            <img src={sarah3} className="img-fluid" alt="Sarah"/>
                        </div>
                    </div>
                </div>

                <Masonry/>

            </div>
        )
    }
}