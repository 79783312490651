import React from "react";
import './Item.css';

function importAll(r) {
    return r.keys().map(r);
}

function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const images = importAll(require.context('./images', false, /\.(jpe?g)$/));

class MasonryItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showingImage: false,
            time: Date.now()
        };

        this.interval = setInterval(() => this.updateImageThing(), randomIntFromInterval(10000, 90000));
    }

    updateImageThing() {
        this.setState({time: Date.now()});

        this.setState({
            showingImage: true
        });

        setTimeout(() => {
            this.setState({
                showingImage: false
            });
        }, 500);
    }

    render() {
        return (
            <div className={`mItem ${this.state.showingImage ? 'image-hidden' : 'image-shown'}`}>
                <img src={images[Math.floor(Math.random() * images.length)]} alt=""/>
            </div>
        );
    }
}

export default MasonryItem;